<template>
  <div v-if="isLoading" class="loader-container">
    <Loader color-class="l-grey" />
  </div>
  <NotFoundPage v-else-if="!isLoading && !CAREER.career_id" />
  <section
    v-else-if="!isLoading && CAREER.career_id"
    class="career-section"
    @click="$emit('redirect')"
  >
    <div class="career-section__header">
      <BreadCrumb class="top-left" />

      <div class="career-section__header__banner do-flex">
        <div class="career-section__header__banner__text">
          <div class="career-section__header__banner__text__title">
            Lowongan Karir
          </div>
        </div>
        <ImageLazy
          class="career-section__header__banner__image"
          :src="imgSrcBanner"
          alt="Karir Lion Parcel"
          @error="event => setImageDefault(event, 'career')"
        />
      </div>
    </div>
    <div class="career-section__detail">
      <div class="career-section__detail__content">
        <div class="career-section__detail__content__description">
          <span class="title">Deskripsi Pekerjaan</span>
          <div v-html="CAREER.job_description" />
        </div>
        <div class="career-section__detail__content__qualification">
          <span class="title">Kualifikasi Minimum</span>
          <div v-html="CAREER.min_qualification" />
        </div>
        <div class="career-section__detail__content__spesification">
          <span class="title">Spesifikasi Lowongan Kerja</span>
          <div class="career-section__detail__content__spesification__level">
            <span class="subtitle">Tingkat Pekerjaan</span>
            <div class="content">
              {{ CAREER.job_level }}
            </div>
          </div>
          <div class="career-section__detail__content__spesification__category">
            <span class="subtitle">Kategori Lowongan</span>
            <div class="content">
              {{ CAREER.employment_type }}
            </div>
          </div>
        </div>
      </div>
      <CareerCard
        :position="CAREER.job_position"
        :status="CAREER.employment_status"
        :division="CAREER.division_name"
        :location="CAREER.city"
        :is-use-button="true"
      >
        <Button
          v-if="!isMobileResponsive"
          text="Lamar Sekarang"
          @click="goToForm"
        />
      </CareerCard>
    </div>
    <div v-if="isMobileResponsive" class="sticky-button">
      <div class="sticky-button__content">
        <Button text="Lamar Sekarang" @click="goToForm" />
      </div>
    </div>
  </section>
</template>
<script>
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb';
import CareerCard from '@/pages/new-career/components/CareerCard.vue';
import Button from '@/components/new-button/Button.vue';
import Loader from '@/components/new-loader/Loader.vue';
import CareerUsecase from '../app/usecase/career';
import ImageLazy from '@/components/ImageLazy/index.vue';
import { setImageDefault } from '@/components/ImageLazy/util';
import mixinMobile from '@/misc/mixinMobile';
import mixinMetaInfo from '@/misc/mixinMetaInfo';
import NotFoundPage from '@/pages/not-found/NotFound.vue';
import defaultSso from '@/helpers/SEODefault'
import { mapGetters } from 'vuex';

export default {
  components: {
    BreadCrumb,
    CareerCard,
    Button,
    Loader,
    ImageLazy,
    NotFoundPage,
  },
  mixins: [mixinMobile, mixinMetaInfo],
  metaInfo() {
    return this.SEO({
      ...defaultSso,
      channel: 'article',
      title: 'Karir ' + this.CAREER?.job_position,
      description:
        'Karir ' +
        this.CAREER?.job_position +
        ' - ' +
        this.CAREER.meta_info_description,
      keywords:
        'karir, ' +
        this.CAREER?.job_position
          ?.toLowerCase()
          .split(' & ')
          .join(' ')
          .split(', ')
          .join(' ')
          .split(' ')
          .join(', ') +
        ', ' +
        this.CAREER.meta_info_keyword,
      publishedTime: this.CAREER.datetime_event,
      image: this.CAREER.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      isLoading: false,
      setImageDefault,
    };
  },
  async asyncData({ route, store }) {
    const res = await CareerUsecase.getDetail(route.params.career_id);
    if (res.data && res.data.status === 'INACTIVE') {
      store.dispatch('setCareer', {
        job_position: 'Not Found',
      });
    } else {
      store.dispatch('setCareer', res.data);
    }
  },
  computed: {
    ...mapGetters(['CAREER']),
    isMobileResponsive() {
      return this.windowWidth <= 599;
    },
    imgSrcBanner() {
      return this.isMobileResponsive
        ? '/images/career/banner_career_job_mobile.webp'
        : '/images/career/banner_career_job.webp';
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.getCareer();
  },
  methods: {
    async getCareer() {
      this.isLoading = true;
      const res = await CareerUsecase.getDetail(this.$route.params.career_id);
      if (res.data && res.data.status === 'INACTIVE') {
        this.$store.dispatch('setCareer', {
          job_position: 'Not Found',
        });
      } else {
        this.$store.dispatch('setCareer', res.data);
      }
      this.isLoading = false;
    },
    goToForm() {
      this.$router.push('/career/form/' + this.CAREER.career_id);
    },
  },
};
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
